import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MessageData } from '../../../models/bases/message-data';
import { MatIconModule } from '@angular/material/icon';
import { NgForOf, NgIf, NgStyle } from "@angular/common";
import { MatButton } from "@angular/material/button";

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [MatIconModule, NgIf, NgStyle, MatButton, NgForOf],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: MessageData,
              private snackBarRef: MatSnackBarRef<AlertComponent>

  ) {
  }
  onOkClick(): void {
    this.snackBarRef.dismiss();
  }
}
