import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageData } from '../models/bases/message-data';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResponse } from "../models/bases/base-api-response";

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  isAlertOpen = false;
  defaultDuration: number = 5000;
  private alertQueue: any[] = [];

  constructor(
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService
  ) {
  }

  // alertError(data: { msg?: string; title?: string; duration?: number; filedMessage?: ErrorResponse ; }) {
  //   const messageData: MessageData = {
  //     title: data.title
  //       ? data.title
  //       : this._translateService.instant('alert.error.title'),
  //     message: data.msg,
  //     type: 'error',
  //     fieldsAndMessage: data.filedMessage
  //   };
  //   this._snackbar.openFromComponent(AlertComponent, {
  //     data: messageData,
  //     duration: data.duration ? data.duration : this.defaultDuration,
  //     panelClass: ['custom-snackbar-panel', 'error'],
  //   });
  // }
  alertError(data: { msg?: string; title?: string; duration?: number; filedMessage?: ErrorResponse }) {
    // Add the alert to the queue
    this.alertQueue.push(data);
    this.processQueue();
  }

  alertInstruction(data: { msg: string; title?: string; duration?: number }) {
    const messageData: MessageData = {
      title: data.title
        ? data.title
        : this._translateService.instant('alert.instruction.title'),
      message: data.msg,
      type: 'instruction',
    };
    this._snackbar.openFromComponent(AlertComponent, {
      data: messageData,
      duration: data.duration ? data.duration : this.defaultDuration,
      panelClass: ['custom-snackbar-panel', 'instruction'],
    });
  }

  alertSuccess(data: { msg: string; title?: string; duration?: number }) {
    const messageData: MessageData = {
      title: data.title
        ? data.title
        : this._translateService.instant('alert.success.title'),
      message: data.msg,
      type: 'success',
    };
    this._snackbar.openFromComponent(AlertComponent, {
      data: messageData,
      duration: data.duration ? data.duration : this.defaultDuration,
      panelClass: ['custom-snackbar-panel', 'success'],
    });
  }

  alertWarning(data: { msg: string; title?: string; duration?: number }) {
    const messageData: MessageData = {
      title: data.title
        ? data.title
        : this._translateService.instant('alert.warning.title'),
      message: data.msg,
      type: 'warning',
    };
    this._snackbar.openFromComponent(AlertComponent, {
      data: messageData,
      duration: data.duration ? data.duration : this.defaultDuration,
      panelClass: ['custom-snackbar-panel', 'warning'],
    });
  }

  private processQueue() {
    if (this.isAlertOpen || this.alertQueue.length === 0) {
      return;
    }
    this.isAlertOpen = true;
    const data = this.alertQueue.shift();
    const messageData: MessageData = {
      title: data.title
        ? data.title
        : this._translateService.instant('alert.error.title'),
      message: data.msg,
      type: 'error',
      fieldsAndMessage: data.filedMessage
    };
    const snackBarRef = this._snackbar.openFromComponent(AlertComponent, {
      data: messageData,
      duration: data.duration ?? this.defaultDuration,
      panelClass: ['custom-snackbar-panel', 'error'],
    });
    snackBarRef.afterDismissed().subscribe(() => {
      this.isAlertOpen = false;
      this.processQueue();
    });
  }
}
